import React from "react";

//login
// const Login = React.lazy(() => import("../Modules/Login/Login"));

//Profile
const Profile = React.lazy(() => import("../Modules/Profile/Profile"));

//Beranda
// const Beranda = React.lazy(() => import("../Modules/Beranda/Beranda"));
const BerandaNew = React.lazy(() => import("../Modules/Beranda/BerandaNew"));
const VisiMisiPerusahaan = React.lazy(() =>
  import("../Modules/Beranda/VisiMisiPerusahaan")
);

const ProfileKaryawan = React.lazy(() =>
  import("../Modules/Profile/ProfileKaryawan")
);

//Pengaturan
const IndukPerusahaan = React.lazy(() =>
  import("../Modules/Pengaturan/IndukPerusahaan/IndukPerusahaan")
);
const AnakPerusahaan = React.lazy(() =>
  import("../Modules/Pengaturan/AnakPerusahaan/AnakPerusahaan")
);
const PengaturanVisiMisiPerusahaan = React.lazy(() =>
  import("../Modules/Pengaturan/VisiMisiPerusahaan/VisiMisiPerusahaan")
);
const PengaturanPendidikan = React.lazy(() =>
  import("../Modules/Pengaturan/Pendidikan/Pendidikan")
);
const PengaturanPsikotes = React.lazy(() =>
  import("../Modules/Pengaturan/Psikotes/Psikotes")
);
const PengelolaanKaryawan = React.lazy(() =>
  import("../Modules/Pengaturan/PengelolaanKaryawan/PengelolaanKaryawan")
);

const AddPenilaianInduksiPengaturan = React.lazy(() =>
  import(
    "../Modules/Pengaturan/PengelolaanKaryawan/AddPenilaianInduksiPengaturan"
  )
);

const DetailPenilaianInduksiPengaturan = React.lazy(() =>
  import(
    "../Modules/Pengaturan/PengelolaanKaryawan/DetailPenilaianInduksiPengaturan"
  )
);

const Persetujuan = React.lazy(() =>
  import("../Modules/Pengaturan/Persetujuan/PersetujuanKaryawanNew")
);

const HakAkses = React.lazy(() =>
  import("../Modules/Pengaturan/HakAkses/HakAkses")
);

const HakAksesNew = React.lazy(() =>
  import("../Modules/Pengaturan/HakAksesNew/HakAksesNew")
);

const CreateHakAkses = React.lazy(() =>
  import("../Modules/Pengaturan/HakAkses/CraateHakAkses")
);

const CreateHakAksesNew = React.lazy(() =>
  import("../Modules/Pengaturan/HakAksesNew/CreateHakAksesNew")
);

const EditHakAkses = React.lazy(() =>
  import("../Modules/Pengaturan/HakAkses/EditHakAkses")
);

const EditHakAksesNew = React.lazy(() =>
  import("../Modules/Pengaturan/HakAksesNew/EditHakAksesNew")
);

const UserRole = React.lazy(() =>
  import("../Modules/Pengaturan/UserRole/userRole")
);

const EditUserRole = React.lazy(() =>
  import("../Modules/Pengaturan/UserRole/editUserRole")
);

const CreateUserRole = React.lazy(() =>
  import("../Modules/Pengaturan/UserRole/createUserRole")
);

//Struktur
const LokasiKerja = React.lazy(() =>
  import("../Modules/Pengaturan/LokasiKerja/LokasiKerja")
);

const LokasiKerjaNew = React.lazy(() =>
  import("../Modules/Pengaturan/LokasiKerja/LokasiKerjaNew")
);

const LokasiKerjaDetailNew = React.lazy(() =>
  import("../Modules/Pengaturan/LokasiKerja/LokasiKerjaDetailNew")
);

const AddLokasiKerjaNew = React.lazy(() =>
  import("../Modules/Pengaturan/LokasiKerja/AddLokasiKerjaNew")
);

const UpdateLokasiKerja = React.lazy(() =>
  import("../Modules/Pengaturan/LokasiKerja/UpdateLokasiKerja")
);

const MasterDivisi = React.lazy(() =>
  import("../Modules/Pengaturan/MasterDivisi/MasterDivisi")
);

const AddMasterDivisi = React.lazy(() =>
  import("../Modules/Pengaturan/MasterDivisi/AddMasterDivisi")
);

const DetailMasterDivisi = React.lazy(() =>
  import("../Modules/Pengaturan/MasterDivisi/MasterDivisiDetail")
);

const EditMasterDivisi = React.lazy(() =>
  import("../Modules/Pengaturan/MasterDivisi/EditMasterDivisi")
);

const MasterStruktur = React.lazy(() =>
  import("../Modules/Pengaturan/MasterStruktur/MasterStruktur")
);

const AddMasterStruktur = React.lazy(() =>
  import("../Modules/Pengaturan/MasterStruktur/AddMasterStruktur")
);

const DetailMasterStruktur = React.lazy(() =>
  import("../Modules/Pengaturan/MasterStruktur/MasterStrukturDetail")
);

const EditMasterStruktur = React.lazy(() =>
  import("../Modules/Pengaturan/MasterStruktur/EditMasterStruktur")
);

const Zona = React.lazy(() => import("../Modules/Pengaturan/Zona/Zona"));

const AddZona = React.lazy(() => import("../Modules/Pengaturan/Zona/AddZona"));

const ZonaDetail = React.lazy(() =>
  import("../Modules/Pengaturan/Zona/ZonaDetail")
);

const EditZona = React.lazy(() =>
  import("../Modules/Pengaturan/Zona/EditZona")
);

const Umk = React.lazy(() => import("../Modules/Pengaturan/UMK/UmkNew"));

const RevisiUmk = React.lazy(() =>
  import("../Modules/Pengaturan/UMK/RevisiUmkNew")
);

const AddUmk = React.lazy(() => import("../Modules/Pengaturan/UMK/AddUmk"));

const GolonganDanSubGolongan = React.lazy(() =>
  import(
    "../Modules/Pengaturan/GolonganDanSubGolongan/GolonganDanSubGolonganNew"
  )
);

const GolonganDanSubGolonganDetail = React.lazy(() =>
  import(
    "../Modules/Pengaturan/GolonganDanSubGolongan/DetailGolonganDanSubGolonganNew"
  )
);

const AddGolonganDanSubGolongan = React.lazy(() =>
  import(
    "../Modules/Pengaturan/GolonganDanSubGolongan/AddGolonganDanSubGolonganNew"
  )
);

const EditGolonganDanSubGolongan = React.lazy(() =>
  import(
    "../Modules/Pengaturan/GolonganDanSubGolongan/EditGolonganDanSubGolongan"
  )
);

const StatusKepegawaian = React.lazy(() =>
  import("../Modules/Pengaturan/StatusKepegawaian/StatusKepegawaianNew")
);

const DetailStatusKepegawaian = React.lazy(() =>
  import("../Modules/Pengaturan/StatusKepegawaian/DetailStatusKepegawaianNew")
);

const AddStatusKepegawaian = React.lazy(() =>
  import("../Modules/Pengaturan/StatusKepegawaian/AddStatusKepegawaianNew")
);

const EditStatusKepegawaian = React.lazy(() =>
  import("../Modules/Pengaturan/StatusKepegawaian/EditStatusKepegawaian")
);

const Jabatan = React.lazy(() =>
  import("../Modules/Pengaturan/Jabatan/JabatanNew")
);

const DetailJabatan = React.lazy(() =>
  import("../Modules/Pengaturan/Jabatan/DetailJabatanNew")
);

const AddJabatan = React.lazy(() =>
  import("../Modules/Pengaturan/Jabatan/AddJabatanNew")
);

const EditJabatan = React.lazy(() =>
  import("../Modules/Pengaturan/Jabatan/EditJabatan")
);

const Rekrut = React.lazy(() => import("../Modules/Rekrut/Rekrut"));

//rekrut
const DataMaster = React.lazy(() =>
  import("../Modules/Rekrut/DataMaster/DataMaster")
);

const AddDataMaster = React.lazy(() =>
  import("../Modules/Rekrut/DataMaster/AddDataMaster")
);

const DetailDataMaster = React.lazy(() =>
  import("../Modules/Rekrut/DataMaster/DetailDataMaster")
);

const PengajuanKaryawan = React.lazy(() =>
  import("../Modules/Rekrut/PengajuanKaryawan/PengajuanKaryawan")
);

const AddPengajuanKaryawan = React.lazy(() =>
  import("../Modules/Rekrut/PengajuanKaryawan/AddPengajuanKaryawan")
);

const KaryawanMagang = React.lazy(() =>
  import("../Modules/Rekrut/KaryawanMagang/KaryawanMagang")
);

const EditDataKaryawanMagang = React.lazy(() =>
  import("../Modules/Rekrut/KaryawanMagang/EditKaryawanMagang")
);

const TambahDataKaryawanMagang = React.lazy(() =>
  import("../Modules/Rekrut/KaryawanMagang/TambahDataKaryawanMagang")
);

const DetailDataKaryawanMagang = React.lazy(() =>
  import("../Modules/Rekrut/KaryawanMagang/DetailKaryawanMagang")
);

const PenilaianKaryawanMagang = React.lazy(() =>
  import("../Modules/Rekrut/KaryawanMagang/PenilaianMagang")
);

const DetailPengajuanKaryawan = React.lazy(() =>
  import("../Modules/Rekrut/PengajuanKaryawan/DetailPengajuanKaryawan")
);

const DetailPengajuanKaryawanApproval = React.lazy(() =>
  import("../Modules/Rekrut/PengajuanKaryawan/DetailPengajuanKaryawanApproval")
);

const AjukanUlangKaryawan = React.lazy(() =>
  import("../Modules/Rekrut/PengajuanKaryawan/AjukanUlangKaryawan")
);

const Rekrutmen = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/rekrutmen")
);

const PostingLowongan = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/PostingLowongan")
);

const PostingLowonganDetail = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/PostingLowonganDetail")
);

const PostingLowongan2 = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/PostingLowongan2")
);

const AturLowongan = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/AturLowongan")
);

const AturLowonganDetail = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/AturLowonganDetail")
);

const CariKandidat = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/Carikandidat")
);

const CariKandidatDetail = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/CariKandidatDetail")
);

//wawancara
const Wawancara = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/Wawancara")
);

const PenilaianWawancara = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/PenilaianWawancara")
);

const Psikotes = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/Psikotes")
);
const HasillPsikotes = React.lazy(() =>
  import("../Modules/Rekrut/Rekrutmen/HasilPsikotes")
);

const AddPenilaianInduksi = React.lazy(() =>
  import("../Modules/Rekrut/DataMaster/AddPenilaianInduksi")
);

const DetailPenilaianInduksi = React.lazy(() =>
  import("../Modules/Rekrut/DataMaster/DetailPenilaianInduksi")
);

const PengangkatanKaryawan = React.lazy(() =>
  import("../Modules/Rekrut/PengangkatanKaryawan/PengangkatanKaryawan")
);

const PenerimaanKaryawanMagang = React.lazy(() =>
  import("../Modules/Rekrut/PenerimaanKaryawanMagang/PenerimaanKaryawanMagang")
);

const ProfilPelamar = React.lazy(() =>
  import("../Modules/Rekrut/PenerimaanKaryawanMagang/ProfilPelamar")
);

const PenilaianInduksi = React.lazy(() =>
  import("../Modules/Rekrut/PenilaianInduksi/PenilaianInduksi")
);

const PenilaianInduksiPembimbing = React.lazy(() =>
  import("../Modules/Rekrut/PenilaianInduksi/PenilaianInduksiPembimbing")
);

const PenilaianInduksiAtasan = React.lazy(() =>
  import("../Modules/Rekrut/PenilaianInduksi/PenilaianInduksiAtasan")
);

//soal
// const Soal = React.lazy(() => import("../Modules/Soal/Soal"));

//Soon
const ComingSoon = React.lazy(() => import("../Modules/ComingSoon/ComingSoon"));

//Data Karyawan
const DataKaryawan = React.lazy(() =>
  import("../Modules/Pengelolaan/DataKaryawan/DataKaryawan")
);

const DaftarKaryawan = React.lazy(() =>
  import("../Modules/Pengelolaan/DataKaryawan/DaftarKaryawan")
);

const ListPengangkatanKaryawan = React.lazy(() =>
  import("../Modules/Pengelolaan/PengangkatanKaryawan/PengangkatanKaryawan")
);

const DetailPengangkatanKaryawan = React.lazy(() =>
  import("../Modules/Pengelolaan/PengangkatanKaryawan/DetailPengangkatanKaryawan")
);

const DetailKaryawan = React.lazy(() => import('../Modules/Pengelolaan/DataKaryawan/DetailKaryawan'))

// const AksesUser = React.lazy(() => import('../Modules/Pengelolaan/AksesUser/AksesUser'))

// const EditAksesUser = React.lazy(() => import('../Modules/Pengelolaan/AksesUser/EditAksesUser'))

const EditKaryawan = React.lazy(() => import('../Modules/Pengelolaan/DataKaryawan/EditKaryawan'))

const TambahDataKaryawan = React.lazy(() =>
  import("../Modules/Pengelolaan/DataKaryawan/TambahDataKaryawan")
);
const TambahDataKaryawanNew = React.lazy(() =>
  import("../Modules/Pengelolaan/DataKaryawan/AddKaryawan")
);
const DetailDataKaryawan = React.lazy(() =>
  import("../Modules/Pengelolaan/DataKaryawan/DetailDataKaryawan")
);

const PengaturanKaryawanBaru = React.lazy(() =>
  import("../Modules/Pengelolaan/PengaturanKaryawanbaru/PengaturanKaryawanBaru")
);

// Pengajuan
const Pengajuan = React.lazy(() =>
  import("../Modules/Pengelolaan/Pengajuan/Pengajuan")
);

const PengajuanNew = React.lazy(() =>
  import("../Modules/Pengelolaan/Pengajuan/PengajuanNew")
);

const AddPengajuanNew = React.lazy(() =>
  import("../Modules/Pengelolaan/Pengajuan/AddPengajuanNew")
);

const DetailPengajuan = React.lazy(() =>
  import("../Modules/Pengelolaan/Pengajuan/DetailPengajuan")
);

const DetailPengajuanNew = React.lazy(() =>
  import("../Modules/Pengelolaan/Pengajuan/DetailPengajuanNew")
);

const EditPengajuan = React.lazy(() =>
  import("../Modules/Pengelolaan/Pengajuan/EditPengajuan")
);

const PengajuanCuti = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanCuti/PengajuanCuti"
  )
);

const DetailPengajuanCuti = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanCuti/DetailPengajuanCuti"
  )
);

const PengajuanMutasi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanMutasi/PengajuanMutasi"
  )
);

const DetailPengajuanMutasi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanMutasi/DetailPengajuanMutasi"
  )
);

const PengajuanPengunduranDiri = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanPengunduranDiri/PengajuanPengunduranDiri"
  )
);

const DetailPengajuanPengunduranDiri = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanPengunduranDiri/DetailPengajuanPengunduranDiri"
  )
);

const PengajuanPembaruanData = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanPembaruanData/PengajuanPembaruanData"
  )
);

const DetailPengajuanPembaruanData = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanPembaruanData/DetailPengajuanPembaruanData"
  )
);

const PengajuanSanksi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanSanksi/PengajuanSanksi"
  )
);

const DetailPengajuanSanksi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/PengajuanSanksi/DetailPengajuanSanksi"
  )
);

// const PengajuanPembaruanData = React.lazy(() =>
// import("../Modules/Pengelolaan/Pengajuan/Components/");
// );

const Bast = React.lazy(() =>
  import("../Modules/Pengelolaan/Pengajuan/Components/BAST/Bast")
);

const PersetujuanBast = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Persetujuan/Components/PersetujuanBAST/PersetujuanBast"
  )
);

const WawancaraKeluarBast = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Pengajuan/Components/WawancaraKeluarBast/WawancaraKeluarBast"
  )
);

const PersetujuanWawancaraKeluarBast = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Persetujuan/Components/PersetujuanWawancaraKeluarBast/WawancaraKeluarBast"
  )
);

const PembaruanStatusKepegawaian = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PembaruanStatusKepegawaian/PembaruanStatusKepegawaian"
  )
);

const DetailPembaruanStatusKepegawaian = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PembaruanStatusKepegawaian/DetailPembaruanStatusKepegawaian"
  )
);

const PengelolaanPersetujuan = React.lazy(() =>
  import("../Modules/Pengelolaan/Persetujuan/Persetujuan")
);

const PengelolaanPersetujuanNew = React.lazy(() =>
  import("../Modules/Pengelolaan/Persetujuan/PersetujuanNew")
);

const DetailPengelolaanPersetujuanNew = React.lazy(() =>
  import("../Modules/Pengelolaan/Persetujuan/DetailPersetujuanNew")
);

const PersetujuanMutasi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Persetujuan/Components/PersetujuanMutasi.js/PersetujuanMutasi"
  )
);

const PersetujuanSanksi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Persetujuan/Components/PersetujuanSanksi/PersetujuanSanksi"
  )
);

const PersetujuanPengunduranDiri = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Persetujuan/Components/PersetujuanPengunduranDiri/PersetujuanPengunduranDiri"
  )
);

const PersetujuanPembaruanData = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Persetujuan/Components/PersetujuanPembaruanData/PersetujuanPembaruanData"
  )
);

const PersetujuanCuti = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/Persetujuan/Components/PersetujuanCuti/PersetujuanCuti"
  )
);

//promosi
const Promosi = React.lazy(() =>
  import("../Modules/Pengelolaan/Promosi/Promosi")
);

const DetailPromosi = React.lazy(() =>
  import("../Modules/Pengelolaan/Promosi/DetailPromosi")
);

//purna tugas
const PurnaTugas = React.lazy(() =>
  import("../Modules/Pengelolaan/PurnaTugas/PurnaTugas")
);

const DetailPurnaTugas = React.lazy(() =>
  import("../Modules/Pengelolaan/PurnaTugas/DetailPurnaTugas")
);

//Masa Persiapan Pensiun
const MasaPersiapanPensiun = React.lazy(() =>
  import("../Modules/Pengelolaan/MasaPersiapanPensiun/MasaPersiapanPensiun")
);

//Penilaian Promosi
const PersiapanPromosi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PenilaianPromosi/PersiapanPromosi/PersiapanPromosi"
  )
);

const AddAturJadwal = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PenilaianPromosi/PersiapanPromosi/AddAturJadwal"
  )
);

const DetailAturJadwal = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PenilaianPromosi/PersiapanPromosi/DetailAturJadwal"
  )
);

const PenilaianPanelis = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PenilaianPromosi/PenilaianPanelis/PenilaianPanelis"
  )
);

const BeriPenilaianPanelis = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PenilaianPromosi/PenilaianPanelis/BeriPenilaianPanelis"
  )
);

const PersetujuanPromosi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PenilaianPromosi/PersetujuanPromosi/PersetujuanPromosi"
  )
);

const DetailPersetujuanPromosi = React.lazy(() =>
  import(
    "../Modules/Pengelolaan/PenilaianPromosi/PersetujuanPromosi/DetailPersetujuanPromosi"
  )
);

// Master Perjalanan Dinas
const PerjalananDinas = React.lazy(() =>
  import("../Modules/Pengaturan/PerjalananDInas/PerjalananDinas")
);

// Perjalanan Dinas
const PengajuanJaldin = React.lazy(() =>
  import("../Modules/PerjalananDinas/Pengajuan/PengajuanJaldin")
);

const PengajuanUangMukaKlaim = React.lazy(() =>
  import(
    "../Modules/PerjalananDinas/Pengajuan/Components/PengajuanUangMukaKlaim/PengajuanUangMukaKlaim"
  )
);

const DetailPengajuanUangMukaKlaim = React.lazy(() =>
  import(
    "../Modules/PerjalananDinas/Pengajuan/Components/PengajuanUangMukaKlaim/DetailPengajuanUangMukaKlaim"
  )
);

const DetailPengajuanJaldin = React.lazy(() =>
  import(
    "../Modules/PerjalananDinas/Pengajuan/Components/PengajuanUangMukaKlaim/DetailPengajuanJaldin"
  )
);

const PengajuanPertanggungJawaban = React.lazy(() =>
  import(
    "../Modules/PerjalananDinas/Pengajuan/Components/PengajuanPertanggungJawab/PengajuanPertanggungJawaban"
  )
);

const DetailPengajuanPertanggungJawaban = React.lazy(() =>
  import(
    "../Modules/PerjalananDinas/Pengajuan/Components/PengajuanPertanggungJawab/DetailPengajuanPertanggungJawaban"
  )
);

const PersetujuanJaldin = React.lazy(() =>
  import("../Modules/PerjalananDinas/Persetujuan/PersetujuanJaldin")
);

const DetailPersetujuanJaldin = React.lazy(() =>
  import(
    "../Modules/PerjalananDinas/Persetujuan/Components/DetailPersetujuan/DetailPersetujuan"
  )
);

// master penggajian
const Penggajian = React.lazy(() =>
  import("../Modules/Pengaturan/Penggajian/Penggajian")
);

const KaUnit = React.lazy(() => import("../Modules/Penggajian/KAUnit/KaUnit"));

const DirekturAP = React.lazy(() =>
  import("../Modules/Penggajian/DirekturAp/DirekturAp")
);

const GenerateGaji = React.lazy(() =>
  import("../Modules/Penggajian/GenerateGaji/generateGaji")
);

const CekGaji = React.lazy(() =>
  import("../Modules/Penggajian/KAUnit/CekGaji")
);

const CekGajiMagangKaunit = React.lazy(() =>
  import("../Modules/Penggajian/KAUnit/CekGajiMagang")
);

const CekGajiDirektur = React.lazy(() =>
  import("../Modules/Penggajian/DirekturAp/CekGaji")
);

const CekGajiDirekturMagang = React.lazy(() =>
  import("../Modules/Penggajian/DirekturAp/CekGajiMagang")
);

const DapengAPHR = React.lazy(() =>
  import("../Modules/Penggajian/DapengAPHR/DapengAPHR")
);

const CekGajiDapengAPHR = React.lazy(() =>
  import("../Modules/Penggajian/DapengAPHR/CekGaji")
);

const PenggajianAP = React.lazy(() =>
  import("../Modules/Penggajian/PenggajianAP/PenggajianAP")
);

const PenggajianHO = React.lazy(() =>
  import("../Modules/Penggajian/PenggajianHO/PenggajianHO")
);

const DetailDapengAp = React.lazy(() =>
  import("../Modules/Penggajian/PenggajianAP/DetailDapengAP")
);

const DetailDapengKaUnit = React.lazy(() =>
  import("../Modules/Penggajian/PenggajianAP/DetailDapengKaUnit")
);

const DetailDapengHr = React.lazy(() =>
  import("../Modules/Penggajian/PenggajianAP/DetailDapengHR")
);

const DapengMJL = React.lazy(() =>
  import("../Modules/Penggajian/DapengMJL/DapengMjl")
);

const CekGajiMJL = React.lazy(() =>
  import("../Modules/Penggajian/DapengMJL/CekGaji")
);

const CekGajiMagangMJL = React.lazy(() =>
  import("../Modules/Penggajian/DapengMJL/CekGajiMagang")
);

const DetailDapengMJL = React.lazy(() =>
  import("../Modules/Penggajian/PenggajianHO/DetailDapengMJL")
);

const UploadGajiAP = React.lazy(() =>
  import("../Modules/Penggajian/UploadGajiAP/UploadGajiAP")
);

const UploadGajiHO = React.lazy(() =>
  import("../Modules/Penggajian/UploadGajiHO/UploadGajiHO")
);

//Laporan

const LaporanDataKaryawan = React.lazy(() =>
  import("../Modules/Laporan/LaporanDataKaryawan/LaporanDataKaryawan")
);

const LaporanOrganisasiJabatan = React.lazy(() =>
  import(
    "../Modules/Laporan/LaporanOrganisasiDanJabatan/LaporanOrganisasiDanJabatan"
  )
);

const LaporanRekrutmenNew = React.lazy(() =>
  import("../Modules/Laporan/LaporanRekrutmen/LaporanRekrutmenNew")
);

const LaporanRekrutmenDetail = React.lazy(() =>
  import("../Modules/Laporan/LaporanRekrutmen/LaporanRekrutmenDetail")
);

const LaporanSSU = React.lazy(() =>
  import("../Modules/Laporan/LaporanOrganisasiDanJabatan/LaporanSSU")
);

const LaporanAssesment = React.lazy(() =>
  import("../Modules/Laporan/LaporanAssesment/LaporanAssesment")
);

const LaporanPerjalananDinas = React.lazy(() =>
  import("../Modules/Laporan/LaporanPerjalananDinas/LaporanPerjalananDinas")
);

const LaporanPenggajian = React.lazy(() =>
  import("../Modules/Laporan/LaporanPenggajian/LaporanPenggajian")
);
// const LaporanKaryawan = React.lazy(() =>
//   import("../Modules/Laporan/LaporanKaryawan/LaporanKaryawan")
// );
const LaporanKaryawanNew = React.lazy(() =>
  import("../Modules/Laporan/LaporanKaryawan/LaporanKaryawanNew")
);

const PengaturanNew = React.lazy(() =>
  import("../Modules/Pengaturan/PengaturanNew")
);

const LaporanNew = React.lazy(() =>
  import("../Modules/Laporan/LaporanNew")
);

const Broadcast = React.lazy(() => import("../Modules/Broadcast/Broadcast"));

const routes = [
  // {
  //   path: "/login",
  //   component: Login,
  //   exact: true,
  //   name: "Login",
  // },
  {
    path: "/profile",
    component: Profile,
    exact: true,
    name: "Profile",
  },
  {
    path: "/profil",
    component: ProfileKaryawan,
    exact: true,
    name: "Profil Karyawan",
  },
  {
    path: "/beranda",
    component: BerandaNew,
    exact: true,
    name: "Beranda",
  },
  {
    path: "/visi-misi-perusahaan",
    component: VisiMisiPerusahaan,
    exact: true,
    name: "VisiMisiPerusahaan",
  },
  {
    path: "/pengaturan/induk-perusahaan",
    component: IndukPerusahaan,
    exact: true,
    name: "IndukPerusahaan",
  },
  {
    path: "/pengaturan/anak-perusahaan",
    component: AnakPerusahaan,
    exact: true,
    name: "AnakPerusahaan",
  },
  {
    path: "/pengaturan/visi-misi-perusahaan",
    component: PengaturanVisiMisiPerusahaan,
    exact: true,
    name: "VisiMisiPerusahaan",
  },
  {
    path: "/pengaturan/pendidikan",
    component: PengaturanPendidikan,
    exact: true,
    name: "PengaturanPendidikan",
  },
  {
    path: "/pengaturan/psikotes",
    component: PengaturanPsikotes,
    exact: true,
    name: "PengaturanPsikotes",
  },
  {
    path: "/pengaturan/pengelolaan-karyawan",
    component: PengelolaanKaryawan,
    exact: true,
    name: "PengelolaanKaryawan",
  },
  {
    path: "/pengaturan/pengelolaan-karyawan/tambah-penilaian-induksi",
    component: AddPenilaianInduksiPengaturan,
    exact: true,
    name: "AddPenilaianInduksiPengaturan",
  },
  {
    path: "/pengaturan/pengelolaan-karyawan/detail-penilaian-induksi/:id",
    component: DetailPenilaianInduksiPengaturan,
    exact: true,
    name: "DetailPenilaianInduksiPengaturan",
  },
  {
    path: "/pengaturan/pengajuan",
    component: Persetujuan,
    exact: true,
    name: "Persetujuan",
  },
  {
    path: "/pengaturan/hak-akses",
    component: HakAkses,
    exact: true,
    name: "HakAkses",
  },
  {
    path: "/pengaturan/hak-akses/add",
    component: CreateHakAkses,
    exact: true,
    name: "CreateHakAkses",
  },
  {
    path: "/pengaturan/hak-akses/edit/:id",
    component: EditHakAkses,
    exact: true,
    name: "EditHakAkses",
  },
  {
    path: "/pengaturan/user-role",
    component: HakAksesNew,
    exact: true,
    name: "HakAksesNew",
  },
  {
    path: "/pengaturan/user-role/add",
    component: CreateHakAksesNew,
    exact: true,
    name: "CreateHakAksesNew",
  },
  {
    path: "/pengaturan/user-role/edit/:id",
    component: EditHakAksesNew,
    exact: true,
    name: "EditHakAksesNew",
  },
  {
    path: "/pengaturan/lokasi-kerja",
    component: LokasiKerjaNew,
    exact: true,
    name: "LokasiKerja",
  },
  {
    path: "/pengaturan/lokasi-kerja/detail/:id",
    component: LokasiKerjaDetailNew,
    exact: true,
    name: "LokasiKerjaDetail ",
  },
  {
    path: "/pengaturan/lokasi-kerja/add",
    component: AddLokasiKerjaNew,
    exact: true,
    name: "AddLokasiKerja ",
  },
  {
    path: "/pengaturan/lokasi-kerja/update/:id",
    component: UpdateLokasiKerja,
    exact: true,
    name: "AddLokasiKerja ",
  },
  {
    path: "/pengaturan/zona",
    component: Zona,
    exact: true,
    name: "Zona ",
  },
  {
    path: "/pengaturan/zona/add",
    component: AddZona,
    exact: true,
    name: "AddZona",
  },
  {
    path: "/pengaturan/zona/detail/:id",
    component: ZonaDetail,
    exact: true,
    name: "ZonaDetail",
  },
  {
    path: "/pengaturan/zona/edit/:id",
    component: EditZona,
    exact: true,
    name: "EditZona",
  },
  {
    path: "/pengaturan/divisi",
    component: MasterDivisi,
    exact: true,
    name: "Divisi ",
  },
  {
    path: "/pengaturan/divisi/add",
    component: AddMasterDivisi,
    exact: true,
    name: "Add Divisi",
  },
  {
    path: "/pengaturan/divisi/detail/:id",
    component: DetailMasterDivisi,
    exact: true,
    name: "Detail Divisi",
  },
  {
    path: "/pengaturan/divisi/edit/:id",
    component: EditMasterDivisi,
    exact: true,
    name: "Detail Divisi",
  },
  {
    path: "/pengaturan/struktur",
    component: MasterStruktur,
    exact: true,
    name: "Departemen ",
  },
  {
    path: "/pengaturan/struktur/add",
    component: AddMasterStruktur,
    exact: true,
    name: "Add Departemen",
  },
  {
    path: "/pengaturan/struktur/detail/:id",
    component: DetailMasterStruktur,
    exact: true,
    name: "Detail Departemen",
  },
  {
    path: "/pengaturan/struktur/edit/:id",
    component: EditMasterStruktur,
    exact: true,
    name: "Detail Departemen",
  },
  {
    path: "/pengaturan/ssu",
    component: Umk,
    exact: true,
    name: "UMK ",
  },
  {
    path: "/pengaturan/ssu/revisi/:year",
    component: RevisiUmk,
    exact: true,
    name: "RevisiUMK ",
  },
  {
    path: "/pengaturan/ssu/add/:year",
    component: AddUmk,
    exact: true,
    name: "TambahDataUMK ",
  },
  {
    path: "/pengaturan/pangkat-dan-golongan",
    component: GolonganDanSubGolongan,
    exact: true,
    name: "GolonganDanSubGolongan",
  },
  {
    path: "/pengaturan/pangkat-dan-golongan/detail/:id",
    component: GolonganDanSubGolonganDetail,
    exact: true,
    name: "GolonganDanSubGolonganDetail",
  },
  {
    path: "/pengaturan/pangkat-dan-golongan/add",
    component: AddGolonganDanSubGolongan,
    exact: true,
    name: "GolonganDanSubGolonganAdd",
  },
  {
    path: "/pengaturan/pangkat-dan-golongan/edit/:id",
    component: EditGolonganDanSubGolongan,
    exact: true,
    name: "GolonganDanSubGolonganAdd",
  },
  {
    path: "/pengaturan/status-kepegawaian",
    component: StatusKepegawaian,
    exact: true,
    name: "StatusKepegawaian",
  },
  {
    path: "/pengaturan/status-kepegawaian/detail/:id",
    component: DetailStatusKepegawaian,
    exact: true,
    name: "DetailStatusKepegawaian",
  },
  {
    path: "/pengaturan/status-kepegawaian/add",
    component: AddStatusKepegawaian,
    exact: true,
    name: "AddStatusKepegawaian",
  },
  {
    path: "/pengaturan/status-kepegawaian/edit/:id",
    component: EditStatusKepegawaian,
    exact: true,
    name: "EditStatusKepegawaian",
  },
  {
    path: "/pengaturan/jabatan",
    component: Jabatan,
    exact: true,
    name: "Jabatan",
  },
  {
    path: "/pengaturan/jabatan/detail/:id",
    component: DetailJabatan,
    exact: true,
    name: "DetailJabatan",
  },
  {
    path: "/pengaturan/jabatan/add",
    component: AddJabatan,
    exact: true,
    name: "AddJabatan",
  },
  {
    path: "/pengaturan/jabatan/edit/:id",
    component: EditJabatan,
    exact: true,
    name: "AddJabatan",
  },
  {
    path: "/coming-soon",
    component: ComingSoon,
    exact: true,
    name: "ComingSoon",
  },
  {
    path: "/rekrutment",
    component: Rekrut,
    exact: true,
    name: "Rekrut",
  },
  {
    path: "/rekrut/data-master",
    component: DataMaster,
    exact: true,
    name: "DataMaster",
  },
  {
    path: "/rekrut/tambah-data-master",
    component: AddDataMaster,
    exact: true,
    name: "AddDataMaster",
  },
  {
    path: "/rekrut/data-master/detail/:id",
    component: DetailDataMaster,
    exact: true,
    name: "DetailDataMaster",
  },
  {
    path: "/rekrut/pengajuan-karyawan",
    component: PengajuanKaryawan,
    exact: true,
    name: "PengajuanKaryawan",
  },
  {
    path: "/rekrut/pengajuan-karyawan/add",
    component: AddPengajuanKaryawan,
    exact: true,
    name: "AddPengajuanKaryawan",
  },
  {
    path: "/rekrut/pengajuan-karyawan/detail/:id",
    component: DetailPengajuanKaryawan,
    exact: true,
    name: "DetailPengajuanKaryawan",
  },
  {
    path: "/rekrut/pengajuan-karyawan/approval/:id",
    component: DetailPengajuanKaryawanApproval,
    exact: true,
    name: "DetailPengajuanKaryawanApproval",
  },
  {
    path: "/rekrut/ajukan-ulang-karyawan/:id",
    component: AjukanUlangKaryawan,
    exact: true,
    name: "AjukanUlangKaryawan",
  },
  {
    path: "/rekrut/rekrutmen",
    component: Rekrutmen,
    exact: true,
    name: "Rekrutmen",
  },
  {
    path: "/rekrut/rekrutmen/posting-lowongan",
    component: PostingLowongan,
    exact: true,
    name: "PostingLowongan",
  },
  {
    path: "/rekrut/rekrutmen/posting-lowongan-detail",
    component: PostingLowonganDetail,
    exact: true,
    name: "PostingLowonganDetail",
  },
  {
    path: "/rekrut/rekrutmen/posting_lowongan/:id/:jabatan_id",
    component: PostingLowongan2,
    exact: true,
    name: "PostingLowongan2",
  },
  {
    path: "/rekrut/rekrutmen/atur-lowongan/:id",
    component: AturLowongan,
    exact: true,
    name: "AturLowongan",
  },
  {
    path: "/rekrut/rekrutmen/atur-lowongan/kandidat/:id",
    component: AturLowonganDetail,
    exact: true,
    name: "AturLowonganDetail",
  },
  {
    path: "/rekrut/rekrutmen/cari-Kandidat",
    component: CariKandidat,
    exact: true,
    name: "CariKandidat",
  },
  {
    path: "/rekrut/rekrutmen/cari-kandidat-detail/:id",
    component: CariKandidatDetail,
    exact: true,
    name: "CariKandidat",
  },
  {
    path: "/rekrut/rekrutmen/wawancara",
    component: Wawancara,
    exact: true,
    name: "Wawancara HRD/User",
  },
  {
    path: "/rekrut/rekrutmen/wawancara/penilaian-wawancara/:id",
    component: PenilaianWawancara,
    exact: true,
    name: "PenilaianWawancara",
  },
  {
    path: "/rekrut/rekrutmen/psikotes",
    component: Psikotes,
    exact: true,
    name: "Psikotes",
  },
  {
    path: "/rekrut/rekrutmen/psikotes/hasil-psikotes/:id",
    component: HasillPsikotes,
    exact: true,
    name: "HasillPsikotes",
  },
  {
    path: "/rekrut/pengangkatan-karyawan",
    component: PengangkatanKaryawan,
    exact: true,
    name: "PengangkatanKaryawan",
  },
  {
    path: "/rekrut/data-master/tambah-penilaian-induksi",
    component: AddPenilaianInduksi,
    exact: true,
    name: "AddPenilaianInduksi",
  },
  {
    path: "/rekrut/data-master/detail-penilaian-induksi/:id",
    component: DetailPenilaianInduksi,
    exact: true,
    name: "DetailPenilaianInduksi",
  },
  {
    path: "/rekrut/penerimaan-karyawan-magang",
    component: PenerimaanKaryawanMagang,
    exact: true,
    name: "PenerimaanKaryawanMagang",
  },
  {
    path: "/rekrut/penerimaan-karyawan-magang/profil-pelamar/:id",
    component: ProfilPelamar,
    exact: true,
    name: "ProfilPelamar",
  },
  {
    path: "/rekrut/wawancara",
    component: Wawancara,
    exact: true,
    name: "Wawancara",
  },
  {
    path: "/rekrut/wawancara/penilaian-wawancara/:id",
    component: PenilaianWawancara,
    exact: true,
    name: "PenilaianWawancara",
  },
  {
    path: "/rekrut/penilaian-induksi",
    component: PenilaianInduksi,
    exact: true,
    name: "PenilaianInduksi",
  },
  {
    path: "/rekrut/penilaian-induksi-pembimbing/:id/:user_id/:menu",
    component: PenilaianInduksiPembimbing,
    exact: true,
    name: "PenilaianInduksiPembimbing",
  },
  {
    path: "/rekrut/penilaian-induksi-atasan/:id/:user_id/:menu",
    component: PenilaianInduksiAtasan,
    exact: true,
    name: "PenilaianInduksiAtasan",
  },
  {
    path: "/rekrut/psikotes",
    component: Psikotes,
    exact: true,
    name: "Psikotes",
  },
  {
    path: "/rekrut/psikotes/hasil-psikotes/:id",
    component: HasillPsikotes,
    exact: true,
    name: "HasillPsikotes",
  },
  {
    path: "/rekrut/karyawan-magang",
    component: KaryawanMagang,
    exact: true,
    name: "KaryawanMagang",
  },
  {
    path: "/rekrut/karyawan-magang/tambah-data-karyawan-magang",
    component: TambahDataKaryawanMagang,
    exact: true,
    name: "TambahDataKaryawanMagang",
  },
  {
    path: "/rekrut/karyawan-magang/data-karyawan-magang/detail/:id",
    component: DetailDataKaryawanMagang,
    exact: true,
    name: "DetailDataKaryawanMagang",
  },
  {
    path: "/rekrut/karyawan-magang/data-karyawan-magang/edit/:id",
    component: EditDataKaryawanMagang,
    exact: true,
    name: "EditDataKaryawanMagang",
  },
  {
    path: "/rekrut/karyawan-magang/data-penilaian-karyawan-magang/detail/:id",
    component: PenilaianKaryawanMagang,
    exact: true,
    name: "PenilaianKaryawanMagang",
  },
  {
    path: "/pengelolaan/pengangkatan-karyawan",
    component: ListPengangkatanKaryawan,
    exact: true,
    name: "ListPengangkatanKaryawan",
  },
  {
    path: "/pengelolaan/pengangkatan-karyawan/detail/:id",
    component: DetailPengangkatanKaryawan,
    exact: true,
    name: 'DetailPengangkatanKaryawan'
  },
  {
    path: "/pengelolaan/data-karyawan",
    component: DaftarKaryawan,
    exact: true,
    name: "DataKaryawan",
  },
  {
    path: "/pengelolaan/data-karyawan/detail/:id",
    component: DetailKaryawan,
    exact: true,
    name: 'DetailKaryawan'
  },
  // {
  //   path: "/pengelolaan/data-karyawan/akses-user/:id",
  //   component: AksesUser,
  //   exact: true,
  //   name: 'AksesUser'
  // },
  // {
  //   path: "/pengelolaan/data-karyawan/edit-akses-user/:id",
  //   component: EditAksesUser,
  //   exact: true,
  //   name: 'EditAksesUser'
  // },
  {
    path: "/pengelolaan/data-karyawan/edit/:id",
    component: EditKaryawan,
    exact: true,
    name: 'EditKaryawan'
  },
  {
    path: "/pengelolaan/data-karyawan/tambah-data-karyawan",
    component: TambahDataKaryawan,
    exact: true,
    name: "TambahDataKaryawan",
  },
  {
    path: "/pengelolaan/data-karyawan/tambah-data-karyawan/new",
    component: TambahDataKaryawanNew,
    exact: true,
    name: "TambahDataKaryawanNew",
  },
  {
    path: "/pengelolaan/data-karyawan/detail-data-karyawan/:id",
    component: DetailDataKaryawan,
    exact: true,
    name: "DetailDataKaryawan",
  },
  {
    path: "/pengelolaan/pengaturan-karyawan-baru",
    component: PengaturanKaryawanBaru,
    exact: true,
    name: "PengaturanKaryawanBaru",
  },
  {
    path: "/pengelolaan/pengajuan",
    component: PengajuanNew,
    exact: true,
    name: "Pengajuan",
  },
  {
    path: "/pengelolaan/pengajuan/add/:type",
    component: AddPengajuanNew,
    exact: true,
    name: "Add Pengajuan",
  },
  {
    path: "/pengelolaan/pengajuan/detail/:type/:id",
    component: DetailPengajuan,
    exact: true,
    name: "Detail Pengajuan",
  },
  {
    path: "/pengelolaan/pengajuan/detail/new/:type/:id",
    component: DetailPengajuanNew,
    exact: true,
    name: "Detail Pengajuan New",
  },
  {
    path: "/pengelolaan/pengajuan/edit/:type/:id",
    component: EditPengajuan,
    exact: true,
    name: "Detail Pengajuan",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-cuti",
    component: PengajuanCuti,
    exact: true,
    name: "PengajuanCuti",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-cuti/detail/:id",
    component: DetailPengajuanCuti,
    exact: true,
    name: "DetailPengajuanCuti",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-mutasi",
    component: PengajuanMutasi,
    exact: true,
    name: "PengajuanMutasi",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-mutasi/detail/:id",
    component: DetailPengajuanMutasi,
    exact: true,
    name: "DetailPengajuanMutasi",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-pengunduran-diri",
    component: PengajuanPengunduranDiri,
    exact: true,
    name: "PengajuanPengunduranDiri",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-pengunduran-diri/detail/:id",
    component: DetailPengajuanPengunduranDiri,
    exact: true,
    name: "DetailPengajuanPengunduranDiri",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-sanksi",
    component: PengajuanSanksi,
    exact: true,
    name: "PengajuanSanksi",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-sanksi/detail/:id",
    component: DetailPengajuanSanksi,
    exact: true,
    name: "DetailPengajuanSanksi",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-pembaruan-data",
    component: PengajuanPembaruanData,
    exact: true,
    name: "PengajuanPembaruanData",
  },
  {
    path: "/pengelolaan/pengajuan/pengajuan-pembaruan-data/detail/:id",
    component: DetailPengajuanPembaruanData,
    exact: true,
    name: "DetailPengajuanPembaruanData",
  },
  {
    path: "/pengelolaan/pengajuan/bast/:id",
    component: Bast,
    exact: true,
    name: "Bast",
  },
  {
    path: "/pengelolaan/persetujuan/bast/:id",
    component: PersetujuanBast,
    exact: true,
    name: "PersetujuanBast",
  },
  {
    path: "/pengelolaan/pembaruan-status-kepegawaian",
    component: PembaruanStatusKepegawaian,
    exact: true,
    name: "PembaruanStatusKepegawaian",
  },
  {
    path: "/pengelolaan/pembaruan-status-kepegawaian/detail/:id",
    component: DetailPembaruanStatusKepegawaian,
    exact: true,
    name: "DetailPembaruanStatusKepegawaian",
  },

  {
    path: "/pengelolaan/pengajuan/bast/wawancara-keluar/:id",
    component: WawancaraKeluarBast,
    exact: true,
    name: "WawancaraKeluarBast",
  },
  {
    path: "/pengelolaan/persetujuan/bast/persetujuan-wawancara-keluar/:id",
    component: PersetujuanWawancaraKeluarBast,
    exact: true,
    name: "PersetujuanWawancaraKeluarBast",
  },
  {
    path: "/pengelolaan/persetujuan",
    component: PengelolaanPersetujuanNew,
    exact: true,
    name: "PengelolaanPersetujuan",
  },
  {
    path: "/pengelolaan/persetujuan/detail/:type/:id",
    component: DetailPengelolaanPersetujuanNew,
    exact: true,
    name: "PengelolaanPersetujuan",
  },
  {
    path: "/pengelolaan/persetujuan/persetujuan-mutasi/detail/:id",
    component: PersetujuanMutasi,
    exact: true,
    name: "PersetujuanMutasi",
  },
  {
    path: "/pengelolaan/persetujuan/persetujuan-sanksi/detail/:id",
    component: PersetujuanSanksi,
    exact: true,
    name: "PersetujuanSanksi",
  },
  {
    path: "/pengelolaan/persetujuan/persetujuan-pengunduran-diri/detail/:id",
    component: PersetujuanPengunduranDiri,
    exact: true,
    name: "PersetujuanPengunduranDiri",
  },
  {
    path: "/pengelolaan/persetujuan/persetujuan-cuti/detail/:id",
    component: PersetujuanCuti,
    exact: true,
    name: "PersetujuanCuti",
  },
  {
    path: "/pengelolaan/persetujuan/persetujuan-pembaruan-data/detail/:id",
    component: PersetujuanPembaruanData,
    exact: true,
    name: "PersetujuanPembaruanData",
  },
  {
    path: "/pengelolaan/promosi",
    component: Promosi,
    exact: true,
    name: "Promosi",
  },
  {
    path: "/pengelolaan/promosi/detail/:id",
    component: DetailPromosi,
    exact: true,
    name: "DetailPromosi",
  },
  {
    path: "/pengelolaan/purna_tugas",
    component: PurnaTugas,
    exact: true,
    name: "Purna",
  },
  {
    path: "/pengelolaan/purna_tugas/detail/:id",
    component: DetailPurnaTugas,
    exact: true,
    name: "DetailPurna",
  },
  {
    path: "/pengelolaan/penilaian-promosi/persiapan-promosi",
    component: PersiapanPromosi,
    exact: true,
    name: "PersiapanPromosi",
  },
  {
    path: "/pengelolaan/penilaian-promosi/atur-jadwal",
    component: AddAturJadwal,
    exact: true,
    name: "AddAturJadwal",
  },
  {
    path: "/pengelolaan/masa-persiapan-pensiun",
    component: MasaPersiapanPensiun,
    exact: true,
    name: "MasaPersiapanPensiun",
  },
  {
    path: "/pengelolaan/penilaian-promosi/detail-atur-jadwal/:id",
    component: DetailAturJadwal,
    exact: true,
    name: "DetailAturJadwal",
  },
  {
    path: "/pengelolaan/penilaian-promosi/penilaian-panelis",
    component: PenilaianPanelis,
    exact: true,
    name: "PenilaianPanelis",
  },
  {
    path: "/pengelolaan/penilaian-promosi/beri-penilaian-panelis/:id",
    component: BeriPenilaianPanelis,
    exact: true,
    name: "BeriPenilaianPanelis",
  },
  {
    path: "/pengelolaan/penilaian-promosi/persetujuan-promosi",
    component: PersetujuanPromosi,
    exact: true,
    name: "PersetujuanPromosi",
  },
  {
    path: "/pengelolaan/penilaian-promosi/detail-persetujuan-promosi/:id",
    component: DetailPersetujuanPromosi,
    exact: true,
    name: "DetailPersetujuanPromosi",
  },
  {
    path: "/pengaturan/perjalanan-dinas",
    component: PerjalananDinas,
    exact: true,
    name: "PerjalananDinas",
  },
  {
    path: "/pengaturan/user",
    component: UserRole,
    exact: true,
    name: "UserRole",
  },
  {
    path: "/pengaturan/user/edit/:id",
    component: EditUserRole,
    exact: true,
    name: "EditUserRole",
  },
  {
    path: "/pengaturan/user/add",
    component: CreateUserRole,
    exact: true,
    name: "CreateUserRole",
  },
  {
    path: "/perjalanan-dinas/pengajuan",
    component: PengajuanJaldin,
    exact: true,
    name: "PengajuanJaldin",
  },
  {
    path: "/perjalanan-dinas/pengajuan/pengajuan-uang-muka-klaim",
    component: PengajuanUangMukaKlaim,
    exact: true,
    name: "PengajuanUangMukaKlaim",
  },
  {
    path: "/perjalanan-dinas/pengajuan/detail-pengajuan-uang-muka-klaim/:id",
    component: DetailPengajuanUangMukaKlaim,
    exact: true,
    name: "DetailPengajuanUangMukaKlaim",
  },
  {
    path: "/perjalanan-dinas/pengajuan/detail/:id",
    component: DetailPengajuanJaldin,
    exact: true,
    name: "DetailPengajuanJaldin",
  },
  {
    path: "/perjalanan-dinas/pengajuan/pertanggungan-jawaban",
    component: PengajuanPertanggungJawaban,
    exact: true,
    name: "PengajuanPertanggungJawaban",
  },
  {
    path: "/perjalanan-dinas/pengajuan/detail-pertanggungan-jawaban/:id",
    component: DetailPengajuanPertanggungJawaban,
    exact: true,
    name: "DetailPengajuanPertanggungJawaban",
  },
  {
    path: "/perjalanan-dinas/persetujuan",
    component: PersetujuanJaldin,
    exact: true,
    name: "PersetujuanJaldin",
  },
  {
    path: "/perjalanan-dinas/persetujuan/detail/:id",
    component: DetailPersetujuanJaldin,
    exact: true,
    name: "DetailPersetujuanJaldin",
  },
  {
    path: "/pengaturan/penggajian",
    component: Penggajian,
    exact: true,
    name: "Penggajian",
  },
  {
    path: "/penggajian/ka-unit",
    component: KaUnit,
    exact: true,
    name: "KaUnit",
  },
  {
    path: "/penggajian/direktur-ap",
    component: DirekturAP,
    exact: true,
    name: "DirekturAP",
  },
  {
    path: "/penggajian/generate-gaji",
    component: GenerateGaji,
    exact: true,
    name: "GenerateGaji",
  },
  {
    path: "/penggajian/penggajian/ka-unit/cek-gaji/:id",
    component: CekGaji,
    exact: true,
    name: "CekGaji",
  },
  {
    path: "/penggajian/penggajian/ka-unit/cek-gaji-magang/:id",
    component: CekGajiMagangKaunit,
    exact: true,
    name: "CekGajiMagangKaunit",
  },
  {
    path: "/penggajian/penggajian/direktur-ap/cek-gaji/:id",
    component: CekGajiDirektur,
    exact: true,
    name: "CekGajiDirektur",
  },
  {
    path: "/penggajian/penggajian/direktur-ap-magang/cek-gaji/:id",
    component: CekGajiDirekturMagang,
    exact: true,
    name: "CekGajiDirekturMagang",
  },
  {
    path: "/penggajian/dapeng-ap-hr",
    component: DapengAPHR,
    exact: true,
    name: "DapengAPHR",
  },
  {
    path: "/penggajian/penggajian/dapeng-ap-hr/cek-gaji/:id",
    component: CekGajiDapengAPHR,
    exact: true,
    name: "CekGajiDapengAPHR",
  },
  {
    path: "/penggajian/penggajian-ap",
    component: PenggajianAP,
    exact: true,
    name: "PenggajianAP",
  },
  {
    path: "/penggajian/penggajian-ho",
    component: PenggajianHO,
    exact: true,
    name: "PenggajianHO",
  },
  {
    path: "/penggajian/dapeng-ap/view/:id/:karyawan_id",
    component: DetailDapengAp,
    exact: true,
    name: "DetailDapengAp",
  },
  {
    path: "/penggajian/dapeng-ka-unit/view/:id/:karyawan_id",
    component: DetailDapengKaUnit,
    exact: true,
    name: "DetailDapengKaUnit",
  },
  {
    path: "/penggajian/dapeng-hr/view/:id",
    component: DetailDapengHr,
    exact: true,
    name: "DetailDapengHr",
  },
  {
    path: "/penggajian/dapeng-mjl",
    component: DapengMJL,
    exact: true,
    name: "DapengMJL",
  },
  {
    path: "/penggajian/penggajian/dapeng-mjl/cek-gaji/:id",
    component: CekGajiMJL,
    exact: true,
    name: "CekGajiMJL",
  },
  {
    path: "/penggajian/penggajian/dapeng-mjl-magang/cek-gaji/:id",
    component: CekGajiMagangMJL,
    exact: true,
    name: "CekGajiMagangMJL",
  },
  {
    path: "/penggajian/penggajian-ho/dapeng-mjl/view/:id",
    component: DetailDapengMJL,
    exact: true,
    name: "DetailDapengMJL",
  },
  {
    path: "/penggajian/upload-gaji-ap",
    component: UploadGajiAP,
    exact: true,
    name: "UploadGajiAP",
  },
  {
    path: "/penggajian/upload-gaji-ho",
    component: UploadGajiHO,
    exact: true,
    name: "UploadGajiHO",
  },
  {
    path: "/laporan/laporan-data-karyawan",
    component: LaporanDataKaryawan,
    exact: true,
    name: "LaporanDataKaryawan",
  },
  {
    path: "/laporan/organisasi-jabatan",
    component: LaporanOrganisasiJabatan,
    exact: true,
    name: "LaporanOrganisasiJabatan",
  },
  {
    path: "/laporan/assesment",
    component: LaporanAssesment,
    exact: true,
    name: "LaporanAssesment",
  },
  {
    path: "/laporan/perjalanan-dinas",
    component: LaporanPerjalananDinas,
    exact: true,
    name: "LaporanPerjalananDinas",
  },
  {
    path: "/laporan/penggajian",
    component: LaporanPenggajian,
    exact: true,
    name: "LaporanPenggajian",
  },
  {
    path: "/laporan/laporan-karyawan",
    component: LaporanKaryawanNew,
    exact: true,
    name: "LaporanKaryawanNew",
  },
  // {
  //   path: "/laporan/laporan-karyawan",
  //   component: LaporanKaryawan,
  //   exact: true,
  //   name: "LaporanKaryawan",
  // },
  {
    path: "/laporan/laporan-rekrutmen",
    component: LaporanRekrutmenNew,
    exact: true,
    name: "LaporanKaryawan",
  },
  {
    path: "/laporan/laporan-rekrutmen-detail/:type",
    component: LaporanRekrutmenDetail,
    exact: true,
    name: "LaporanKaryawanDetail",
  },
  {
    path: "/laporan/laporan-ssu",
    component: LaporanSSU,
    exact: true,
    name: "Laporan Struktur Skala Upah",
  },
  {
    path: "/laporan",
    component: LaporanNew,
    exact: true,
    name: "Laporan",
  },
  {
    path: "/pengaturan",
    component: PengaturanNew,
    exact: true,
    name: "Pengaturan",
  },
  {
    path: "/broadcast",
    component: Broadcast,
    exact: true,
    name: "Broadcast",
  },
];

export default routes;
